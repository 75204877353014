import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";
import router from "../router";
import store from "../store";

console.log(process.env, "process.env");

let http = axios.create({
  baseURL: process.env["VUE_APP_API_URL"],
  timeout: 1800000,
  responseType: "json",
  headers: {
    "Content-Type": "application/json"
  }
});

// 请求qs序列化
http.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (
      config.method === "post" &&
      config.headers["Content-Type"] === "application/x-www-form-urlencoded"
    ) {
      config.data = qs.stringify(config.data, {
        arrayFormat: "indices",
        allowDots: true,
      });
    }
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Message({
      showClose: true,
      message: error && error.data.error_msg,
      type: "error",
    });
    return Promise.reject(error.data.error_msg);
  }
);

// 接收数据，刷新token，封装res.bus_json
http.interceptors.response.use(
  (res) => {
    if (res.data) {
      switch (res.data.code) {
        case 0:
        case "0":
          return res.data;
        case 500:
        case "500":
          store.commit("setLoading", false);
          if (res.data.msg) {
            Message.error({
              showClose: true,
              message: res.data.msg
            });
          }
          return Promise.reject();
        case 403:
        case "403":
          store.commit("setLoading", false);
          Message.warning({
            showClose: true,
            message: "您没有登录权限，请重新登录确认，正在跳转到登录页面"
          });
          sessionStorage.clear();
          setTimeout(() => {
            router.replace({
              path: "/login"
            });
          }, 1000);
          return Promise.reject();
        default:
          //其他默认返回的blob文件流
          return res.data;
      }
    } else {
      store.commit("setLoading", false);
      return res;
    }
  },
  (error) => {
    store.commit("setLoading", false);
    Message.error({
      showClose: true,
      message: error.response.data.message,
    });
  }
);

export default http;
