import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 用户信息
    userInfo: sessionStorage.userInfo
      ? JSON.parse(sessionStorage.userInfo)
      : {},
    // head标题
    headerTitle: "",

    // 档务搜索条件
    searchFileValue: "",

    // 全局loading
    loading: false,
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo;
    },
  },
  mutations: {
    setUserInfo(state, n) {
      sessionStorage.setItem("userInfo", JSON.stringify(n));
      state.userInfo = n;
    },
    setHeaderTitle(state, n) {
      state.headerTitle = n;
    },
    setSearchFileValue(state, n) {
      state.searchFileValue = n;
    },
    setLoading(state, n) {
      state.loading = n;
    },
  },
  actions: {
    commitUserInfo({ commit }, n) {
      // 直接将content结构掉，解构出commit，下面就可以直接调用了
      return new Promise((resolve) => {
        commit("setUserInfo", n); // 直接调用
        resolve();
      });
    },
  },
  modules: {},
});
