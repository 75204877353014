import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 图片剪裁
import VueCropper from "vue-cropper";
// 中央事件总线
import VueBus from "vue-bus";
// 指令
import dialogDrag from "@/public/directives/dialogDrag";
// 请求
import http from "./api";
// 公共方法
import utils from "./public/utils";
// 复制
import VueClipboard from "vue-clipboard2";

Vue.use(VueBus);
Vue.use(ElementUI);
Vue.use(VueCropper);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.directive("v-dialogDrag", dialogDrag);
Vue.prototype.$http = http;
Vue.prototype.$utils = utils;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
