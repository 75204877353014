import http from "./axios";

const req = ({
  basePath = "/api/",
  url,
  method,
  params,
  timeout,
  headers = {},
}) => {
  let options = {
    url: basePath + url,
    method: method,
    timeout: timeout,
    headers,
  };

  method = method.toUpperCase();
  if (method === "GET") {
    options.params = params;
  } else {
    options.data = params;
  }

  return http(options);
};

export default req;
