import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "layout",
    component: (resolve) => {
      require(["../views/layout"], resolve);
    },
    children: [
      {
        path: "/home",
        name: "首页",
        meta: {
          type: "home",
          btnName: "上传资源",
        },
        component: (resolve) => {
          require(["../views/home"], resolve);
        },
      },
      {
        path: "/productService",
        name: "产品与服务",
        meta: {
          type: "productService",
          btnName: "上传资源",
        },
        component: (resolve) => {
          require(["../views/productService"], resolve);
        },
      },
      {
        path: "/teamCustomer",
        name: "团队与客户",
        meta: {
          type: "teamCustomer",
        },
        component: (resolve) => {
          require(["../views/teamCustomer"], resolve);
        },
      },
      {
        path: "/teamManage",
        name: "团队管理",
        meta: {
          type: "teamManage",
          btnName: "添加成员",
        },
        component: (resolve) => {
          require(["../views/teamCustomer"], resolve);
        },
      },
      {
        path: "/customerInfo",
        name: "客户信息",
        meta: {
          type: "customerInfo",
          btnName: "添加客户",
        },
        component: (resolve) => {
          require(["../views/teamCustomer"], resolve);
        },
      },
      {
        path: "/spaceImages",
        name: "空间图库",
        meta: {
          type: "spaceImages",
          btnName: "上传图片",
        },
        component: (resolve) => {
          require(["../views/spaceImages"], resolve);
        },
      },
      {
        path: "/fileClass",
        name: "档务分类",
        meta: {
          type: "fileClass",
          btnName: "添加分类",
        },
        component: (resolve) => {
          require(["../views/fileClass"], resolve);
        },
      },
      {
        path: "/spacePay",
        name: "空间付费",
        meta: {
          type: "spacePay",
        },
        component: (resolve) => {
          require(["../views/spacePay"], resolve);
        },
      },
    ],
  },
  {
    path: "/login",
    name: "登录",
    component: (resolve) => {
      require(["../views/login"], resolve);
    },
  },
  {
    path: "/404",
    component: (resolve) => {
      require(["../views/layout"], resolve);
    },
    hidden: true,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (store.state.userInfo && Object.keys(store.state.userInfo).length > 0) {
//     // 通过vuex state获取当前的用户信息是否存在
//     next();
//   } else {
//     next({
//       path: "/login",
//       query: { redirect: to.fullPath }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
//     });
//   }
// });

export default router;
