import API from "../../api";
import store from "@/store";
import { Message } from "element-ui";

export default {
  // 复制文本
  copyText(v) {
    // v 需要复制的内容
    // 创建元素用于复制
    const aux = document.createElement("input");
    // 设置元素内容
    aux.setAttribute("value", v);
    // 将元素插入页面进行调用
    document.body.appendChild(aux);
    // 复制内容
    aux.select();
    // 将内容复制到剪贴板
    document.execCommand("copy");
    // 删除创建元素
    document.body.removeChild(aux);
    Message.success("已经复制到粘贴板");
  },

  // 打开
  openFile(url) {
    window.open(url, "_black");
  },

  // 下载文件
  downLoadFile(row) {
    const a = document.createElement("a");
    a.href = `${row.fileUrl}?response-content-type=application/octet-stream`;
    a.download = name || "";
    a.click();
    a.remove();
  },

  // 文件上传
  uploadFiles(fileList) {
    const spaceId = store.state.userInfo.id;
    const formData = new FormData();
    fileList.forEach((item) => {
      formData.append("file", item.raw);
    });
    formData.append("spaceNo", spaceId);

    return new Promise((resolve) => {
      API.fileoss(formData).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 获取文件类型
  getClassSetList(type = 1) {
    return new Promise((resolve) => {
      API.getClassSetList({
        type: type,
        pageIndex: 1,
        pageSize: 999,
        isShow: 1,
      }).then(({ data }) => {
        resolve(data.condList);
      });
    });
  },

  // 岗位
  getPostList(id) {
    const spaceId = store.state.userInfo.id;
    return new Promise((resolve) => {
      API.getPostList({
        pageIndex: 1,
        pageSize: 999,
        condition: { userId: id ? id : spaceId },
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 关联合作 也是客户
  getCustomerList() {
    const spaceId = store.state.userInfo.id;
    return new Promise((resolve) => {
      API.getCustomerList({
        pageIndex: 1,
        pageSize: 999,
        condition: { spaceId: spaceId },
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 系统分类
  getSysContentCateList(id) {
    const spaceId = store.state.userInfo.id;
    return new Promise((resolve) => {
      API.getSysContentCateList({
        pageIndex: 1,
        pageSize: 999,
        condition: { userId: id ? id : spaceId },
      }).then(({ data }) => {
        resolve(
          data.result.map((item) => {
            return {
              ...item,
              subList: [],
            };
          })
        );
      });
    });
  },

  // 系统分类 小类
  async getSysContentCateSubclassList(v) {
    const spaceId = store.state.userInfo.id;
    let list = await this.getSysContentCateList();

    return new Promise((resolve) => {
      API.getSysContentCateSubclassList({
        userId: spaceId,
        cateId: list[v].id,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 自定义分类
  getCustomContentCateList(id) {
    const spaceId = store.state.userInfo.id;
    return new Promise((resolve) => {
      // 自定义分类
      API.getCustomContentCateList({
        pageIndex: 1,
        pageSize: 999,
        condition: { userId: id ? id : spaceId },
      }).then(({ data }) => {
        resolve(
          data.result.map((item) => {
            return {
              ...item,
              subList: [],
            };
          })
        );
      });
    });
  },

  // 分类
  getDictionaries(url, userId = "userId") {
    const spaceId = store.state.userInfo.id;
    return new Promise((resolve) => {
      let condition = {};
      condition[userId] = spaceId;

      API[url]({
        pageIndex: 1,
        pageSize: 999,
        condition: condition,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 排序
  sortTable(data, item, index, url) {
    return new Promise((resolve) => {
      let a = JSON.parse(JSON.stringify(data[index - 1]));
      data[index - 1] = JSON.parse(JSON.stringify(data[index]));
      data[index] = JSON.parse(JSON.stringify(a));
      data.map((item) => item.id);

      if (!url) {
        resolve(data);
        return;
      }

      API[url]({
        idList: data.map((item) => item.id),
      }).then(() => {
        resolve();
      });
    });
  },

  // 解析地址栏参数
  getUrlParam(name) {
    const reg = new RegExp("[?&]" + name + "=([^&]*)(&?)", "i");
    const r = location.search.match(reg);
    return r ? r[1] : "";
  },

  //截取视频第一帧作为播放前默认图片
  findVideoCover(url, currentTime = 1) {
    return new Promise((resove) => {
      const video = document.createElement("video"); // 获取视频对象
      // const video = document.createElement("video") // 也可以自己创建video
      video.src = url; // url地址 url跟 视频流是一样的
      const canvas = document.createElement("canvas"); // 获取 canvas 对象
      document.body.appendChild(video);
      document.body.appendChild(canvas);
      const ctx = canvas.getContext("2d"); // 绘制2d
      video.crossOrigin = "anonymous"; // 解决跨域问题，也就是提示污染资源无法转换视频
      video.currentTime = currentTime; // 第一帧
      video.oncanplay = () => {
        canvas.width = video.clientWidth; // 获取视频宽度
        canvas.height = video.clientHeight; //获取视频高度
        // 利用canvas对象方法绘图
        ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // 转换成base64形式
        let imgsrc = canvas.toDataURL("image/png"); // 截取后的视频封面
        video.remove();
        canvas.remove();
        resove(imgsrc);
      };
    });
  },

  // 金额保留两位小数
  setMoney(val) {
    if (!val) {
      return "0.00";
    }
    let value = Math.round(parseFloat(val) * 100) / 100;
    const valMoney = value.toString().split(".");
    if (valMoney.length === 1) {
      value = value.toString() + ".00";
    }
    if (valMoney.length > 1) {
      if (valMoney[1].length < 2) {
        value = value.toString() + "0";
      }
    }
    return value;
  },
  // 日期格式化
  dateFormat(fmt, date) {
    date = new Date(date);
    let o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return fmt;
  },

  // 计算日期间隔
  dateDiff() {
    const productExpTime = store.state.userInfo.productExpTime
      ? store.state.userInfo.productExpTime.split(" ")[0]
      : null;
    if (!productExpTime) return 0;
    const sDate = new Date(productExpTime + " 24:00:00");
    const eDate = new Date(
      this.dateFormat("yyyy-MM-dd", new Date()) + " 00:00:00"
    );

    const endTime = sDate.getTime() / 1000 - eDate.getTime() / 1000;
    return parseInt(endTime / 60 / 60 / 24);
  },
};
