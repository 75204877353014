import req from "@/api/http";
import store from "../store";

export default {
  // 获取验证码
  sendVerCode: (data) =>
    req({
      url: "sms/sendVerCode",
      method: "get",
      params: data,
    }),

  // 登录
  login: (data) =>
    req({
      url: "userManage/login",
      method: "post",
      params: data,
    }),

  // 获取用户信息
  getUserInfo(data) {
    return new Promise((resolve) => {
      req({
        url: "userManage/getUserInfo",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 文件上传
  fileoss: (data) =>
    req({
      url: "eduoss/fileoss",
      method: "post",
      params: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  // 获取空间滚动图
  getScrollImageList(data) {
    return new Promise((resolve) => {
      req({
        url: "scrollImageManage/getScrollImageList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 静态资源 图片
  getKeyPicList(keyList) {
    return new Promise((resolve) => {
      req({
        url: "StaticPicManage/getKeyPicList",
        method: "post",
        params: { keyList },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 静态资源 文本
  getKeyTxtList(keyList) {
    return new Promise((resolve) => {
      req({
        url: "StaticTxtManage/getKeyTxtList",
        method: "post",
        params: { keyList },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // add 空间滚动图
  addScrollImage(data) {
    return new Promise((resolve) => {
      req({
        url: "scrollImageManage/addScrollImage",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // edit 空间滚动图
  updateScrollImage(data) {
    return new Promise((resolve) => {
      req({
        url: "scrollImageManage/updateScrollImage",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // del 空间滚动图
  deleteScrollImage(data) {
    return new Promise((resolve) => {
      req({
        url: "scrollImageManage/deleteScrollImage",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // sort 空间滚动图
  sortScrollImage(data) {
    return new Promise((resolve) => {
      req({
        url: "scrollImageManage/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 首页数据统计
  getSumData: (data) =>
    req({
      url: "home/getSumData",
      method: "post",
      params: data,
    }),

  // 获取文件类型
  getClassSetList: (data) =>
    req({
      url: "class/getClassSetList",
      method: "post",
      params: data,
    }),

  // 获取关联合作 也是客户
  getCustomerList: (data) =>
    req({
      url: "resoureCustomer/getCustomerList",
      method: "post",
      params: data,
    }),

  // 获取系统分类
  getSysContentCateList: (data) =>
    req({
      url: "sysContent/getSysContentCateList",
      method: "post",
      params: data,
    }),
  // 自定义分类
  getCustomContentCateList: (data) =>
    req({
      url: "customContent/getCustomContentCateList",
      method: "post",
      params: data,
    }),
  // 系统分类 小类
  getSysContentCateSubclassList: (data) =>
    req({
      url: "sysContentSubclass/getSysContentCateSubclassList",
      method: "post",
      params: data,
    }),

  // 空间图库
  getGalleryList: (data) =>
    req({
      url: "resoureGallery/getGalleryList",
      method: "post",
      params: data,
    }),

  // 产品服务列表数据
  getProductLinkedList: (data) =>
    req({
      url: "sysContent/getProductLinkedList",
      method: "post",
      params: data,
    }),

  // 学习空间
  getStudyLinkedList: (data) =>
    req({
      url: "sysContent/getStudyLinkedList",
      method: "post",
      params: data,
    }),

  // 新增资源提交
  uploadResoure: (data) =>
    req({
      url: "resoureManage/uploadResoure",
      method: "post",
      params: data,
    }),

  // 上传资源提交
  updateResoure: (data) =>
    req({
      url: "resoureManage/updateResoure",
      method: "post",
      params: data,
    }),

  // 获取文件详情
  getResoureDetail: (data) =>
    req({
      url: "resoureManage/getResoureDetail",
      method: "post",
      params: data,
    }),

  // 删除文件
  deleteResoure: (data) =>
    req({
      url: "resoureManage/deleteResoure",
      method: "post",
      params: data,
    }),

  // 获取档夹详情
  getCaseDetail: (data) =>
    req({
      url: "caseManage/getCaseDetail",
      method: "post",
      params: data,
    }),

  // 获取档夹详情
  getResoureNo: (data) =>
    req({
      url: "resoureManage/getResoureNo",
      method: "post",
      params: data,
    }),

  // 岗位
  getPostList: (data) =>
    req({
      url: "organPostManage/getPostList",
      method: "post",
      params: data,
    }),

  // 团队 也就是 成员
  getMemberList: (data) =>
    req({
      url: "organMemberManage/getMemberList",
      method: "post",
      params: data,
    }),

  // 岗位id查成员
  postIdGetMemberList(data) {
    return new Promise((resolve) => {
      req({
        url: "organPostManage/getMemberList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 添加成员
  addCustomer: (data) =>
    req({
      url: "resoureCustomer/addCustomer",
      method: "post",
      params: data,
    }),

  // 编辑成员
  updateCustomer: (data) =>
    req({
      url: "resoureCustomer/updateCustomer",
      method: "post",
      params: data,
    }),

  // 素材列表
  getSpaceResoureList(data) {
    return new Promise((resolve) => {
      req({
        url: "sysManage/getSpaceResoureList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.pager.result);
      });
    });
  },

  // 省市区
  getAreaList(data) {
    return new Promise((resolve) => {
      req({
        url: "commArea/getAreaList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 删除客户
  deleteCustomer(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureCustomer/deleteCustomer",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 获取邀请二维码
  getQrCode(data) {
    return new Promise((resolve) => {
      req({
        url: "qrCode/getQrCode",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 岗位排序
  sortPostManage(data) {
    return new Promise((resolve) => {
      req({
        url: "organPostManage/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 岗位del
  deletePost(data) {
    return new Promise((resolve) => {
      req({
        url: "organPostManage/deletePost",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 岗位添加
  addPost(data) {
    return new Promise((resolve) => {
      req({
        url: "organPostManage/addPost",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 岗位修改
  updatePost(data) {
    return new Promise((resolve) => {
      req({
        url: "organPostManage/updatePost",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 成员修改
  updateMember(data) {
    return new Promise((resolve) => {
      req({
        url: "organMemberManage/updateMember",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 修改图库
  updateGallery(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureGallery/updateGallery",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // add图库
  addGallery(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureGallery/addGallery",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // del图库
  deleteGallery(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureGallery/deleteGallery",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // sort图库
  sortResoureGallery(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureGallery/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 新增自定义大类
  addCustomContentCate(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/addCustomContentCate",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // edit自定义大类
  updateCustomContentCate(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/updateCustomContentCate",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // del自定义大类
  deleteCustomContentCate(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/deleteCustomContentCate",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  /*系统小类*/
  // search 小类
  getSysContentCateSubclassListPromise(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContentSubclass/getSysContentCateSubclassList",
        method: "post",
        params: {
          userId: store.state.userInfo.id,
          cateId: data.id,
        },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // add 小类
  addSysContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContentSubclass/addSysContentCateSubclass",
        method: "post",
        params: {
          ...data,
          userId: store.state.userInfo.id,
          cateId: data.id,
          id: null,
          name: data.name,
        },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // edit 小类
  updateSysContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContentSubclass/updateSysContentCateSubclass",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // del 小类
  deleteSysContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContentSubclass/deleteSysContentCateSubclass",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // sort 小类
  sortSysContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContentSubclass/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  /* 自定义小类 */
  // search 小类
  getCustomContentCateSubclassList(data) {
    return new Promise((resolve) => {
      req({
        url: "customContentSubclass/getCustomContentCateSubclassList",
        method: "post",
        params: {
          userId: store.state.userInfo.id,
          customContentCateId: data.id,
        },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // add 小类
  addCustomContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "customContentSubclass/addCustomContentCateSubclass",
        method: "post",
        params: {
          userId: store.state.userInfo.id,
          customContentCateId: data.id,
          name: data.name,
        },
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // edit 小类
  updateCustomContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "customContentSubclass/updateCustomContentCateSubclass",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // del 小类
  deleteCustomContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "customContentSubclass/deleteCustomContentCateSubclass",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // sort 小类
  sortCustomContentCateSubclass(data) {
    return new Promise((resolve) => {
      req({
        url: "customContentSubclass/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 查看权限列表
  queryCatePermList(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/queryCatePermList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 设置权限列表
  setQueryPerm(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/setQueryPerm",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 查询 系统分类管理员
  queryCateManagerList(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContent/queryCateManagerList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 设置 系统分类管理员
  setSysContentManager(data) {
    return new Promise((resolve) => {
      req({
        url: "sysContent/setManager",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 查询 自定义分类管理员
  queryCustomContentCateManagerList(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/queryCateManagerList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 设置 自定义分类管理员
  setCustomContentManager(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/setManager",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 删除成员
  deleteMember(data) {
    return new Promise((resolve) => {
      req({
        url: "organMemberManage/deleteMember",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 排序 自定义分类 大类
  sortCustomContent(data) {
    return new Promise((resolve) => {
      req({
        url: "customContent/sort",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 修改资源名称
  updaterResName(data) {
    return new Promise((resolve) => {
      req({
        url: "caseManage/updaterResName",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 创建案例
  createCase(data) {
    return new Promise((resolve) => {
      req({
        url: "caseManage/createCase",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 修改案例
  updateCase(data) {
    return new Promise((resolve) => {
      req({
        url: "caseManage/updateCase",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 删除案例
  deleteCase(data) {
    return new Promise((resolve) => {
      req({
        url: "caseManage/deleteCase",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // pc端扫码登录获取openid
  getPcOpenId(data) {
    return new Promise((resolve) => {
      req({
        url: "wx/getPcOpenId",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 档务管理 查询
  getFileSpaceLinkedList: (data) =>
    req({
      url: "customContent/getFileSpaceLinkedList",
      method: "post",
      params: data,
    }),

  // 资源管理 查询
  getResoureList: (data) =>
    req({
      url: "resoureManage/getResoureList",
      method: "post",
      params: data,
    }),

  // 获取产品设置 查询
  getProductList(data) {
    return new Promise((resolve) => {
      req({
        url: "productSett/getProductList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 支付金额计算
  calPaymentAmount(data) {
    return new Promise((resolve) => {
      req({
        url: "wxPay/calPaymentAmount",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 支付
  wxServicePay(data) {
    return new Promise((resolve) => {
      req({
        url: "wxPay/wxServicePay",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 支付结果
  queryPayResult(data) {
    return new Promise((resolve) => {
      req({
        url: "wxPay/queryPayResult",
        method: "get",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 收款账户
  getAcctList(data) {
    return new Promise((resolve) => {
      req({
        url: "collectAccount/getAcctList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 收款说明
  getCollectInfo(data) {
    return new Promise((resolve) => {
      req({
        url: "collectInfo/getCollectInfo",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },

  // 获取订单记录
  getOrderList(data) {
    return new Promise((resolve) => {
      req({
        url: "wxPay/getOrderList",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 复制素材
  copyResoure(data) {
    return new Promise((resolve) => {
      req({
        url: "resoureManage/copyResoure",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },

  // 复制案例
  copyCase(data) {
    return new Promise((resolve) => {
      req({
        url: "caseManage/copyCase",
        method: "post",
        params: data,
      }).then(({ data }) => {
        resolve(data.result);
      });
    });
  },


};
