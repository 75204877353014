<template>
  <div id="app">
    <router-view :key="key" />
  </div>
</template>

<script>
export default {
    // 测试ci
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="less">
@import "./style/index";
</style>
